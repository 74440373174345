// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
Rails.start()

import * as ActiveStorage from '@rails/activestorage';
ActiveStorage.start()

import "chartkick/chart.js"

import 'channels';
import 'controllers';

// REMOVED TO STOP SERVICE WORKER 
// window.addEventListener('load', () => {
//   navigator.serviceWorker.register('/service-worker.js').then(registration => {
//     console.log('ServiceWorker registered: ', registration);

//     var serviceWorker;
//     if (registration.installing) {
//       serviceWorker = registration.installing;
//       console.log('Service worker installing.');
//     } else if (registration.waiting) {
//       serviceWorker = registration.waiting;
//       console.log('Service worker installed & waiting.');
//     } else if (registration.active) {
//       serviceWorker = registration.active;
//       console.log('Service worker active.');
//     }

//     window.Notification.requestPermission().then(permission => {    
//       if(permission !== 'granted'){
//         throw new Error('Permission not granted for Notification');
//       }
//     });

//   }).catch(registrationError => {
//     console.log('Service worker registration failed: ', registrationError);
//   });
// });



// import 'basic-qr-code-reader.js'
// require("packs/basic-qr-code-reader")

// Below breaks toast messages
// import * as bootstrap from 'bootstrap'

// This fixes toast messages
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

import 'bootstrap-icons/font/bootstrap-icons.css';
import "@fortawesome/fontawesome-free/js/all";
import "../stylesheets/application";

$(document).on("turbo:load", () => {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl)
    })
  
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    var toastElList = [].slice.call(document.querySelectorAll('.toast'))
    var toastList = toastElList.map(function(toastEl) {
    return new bootstrap.Toast(toastEl).show() // No need for options; use the default options
    })
})

$(document).ready(function () {
  $(window).on("scroll", function () {
      if ($(document).scrollTop() > 50) {
          $("#topheader").addClass('scrolled');
          $("#fcard").addClass('scrolled');
      }
      else {
          $("#topheader").removeClass('scrolled');
          $("#fcard").removeClass('scrolled');
      }
  })
});


