// src/controllers/invchart_controller.js
import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import InvChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(InvChartDataLabels);

export default class extends Controller {
  static targets = ["invchart", "investments"]

  connect() {
    // this.chart = new Chartkick.LineChart(this.chartTarget, this.data) 
    this.chart = new Chart(this.invchartTarget.getContext('2d'), {
        type: 'line',
        data: {
          labels: this.xdata,
          datasets: [{
            label: 'NSIL Token Price, USD',
            yAxisID: 'y',
            backgroundColor: 'rgba(161, 198, 247, 1)',
            borderColor: 'rgb(47, 128, 237)',
            data: this.ydata,
            order: 1,
            fill: true,
            tension: 0.4,
            pointRadius: 0
          },{
            label: 'Return Since Inception, %',
            yAxisID: 'TOGGLE_yaxis1',
            backgroundColor: 'white',
            borderColor: 'white',
            data: this.y1data,
            order: 2,
            hidden: true,
            // fill: true,
            tension: 0.4,
            pointRadius: 0
          }]
        },
        plugins: [InvChartDataLabels],
        options: {
          responsive: true,
          maintainAspectRatio: true,
          stacked: true,
          plugins: {
            title: {
              display: false,
              // text: 'Historical performance',
              color: 'white'
            },
            legend: {
              display: true,
              position: 'top',
              labels: {
                usePointStyle: true,
                boxWidth: 6,
                boxHeight: 5
              },
              onClick: function(event, legendItem) {
                var y_axis_id = this.chart.data.datasets[legendItem.datasetIndex].yAxisID;
                // console.log('---------->'+ y_axis_id);
                // console.log('---------->'+ this.chart.options.scales.TOGGLE_yaxis1.ticks.display);
                // console.log('---------->'+ this.chart.data.datasets[legendItem.datasetIndex].hidden);
                // console.log('---------->'+ this.chart.data.datasets[0].fill);
               if(y_axis_id.startsWith('TOGGLE')){
                 //find by name yaxis                     
                   if(this.chart.options.scales.TOGGLE_yaxis1.ticks.display==true){

                      this.chart.options.scales.TOGGLE_yaxis1.ticks.display = false;
                      this.chart.data.datasets[0].fill = true;
                      this.chart.data.datasets[legendItem.datasetIndex].hidden = true;
                      this.chart.update();
                   }
                   else if(this.chart.options.scales.TOGGLE_yaxis1.ticks.display==false){

                    this.chart.options.scales.TOGGLE_yaxis1.ticks.display = true;
                    this.chart.data.datasets[0].fill = false;
                    this.chart.data.datasets[legendItem.datasetIndex].hidden = false;
                    this.chart.update();
                 }
             }
            }
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              intersect: false
            } 
          },
          scales: {
            y: {
              ticks: {
                color: 'white',
              },
              grid: {
                display: true,
              },
              position: 'left',
            },
            TOGGLE_yaxis1: {
              ticks: {
                color: 'white',
                display: false,
                callback: function(value, index, ticks) {
                  return value + '%';
              },
              },
              grid: {
                display: true,
              },
              position: 'right',
            },
            x: {
              ticks: {
                color: 'white',
                // autoSkip: false,
                // maxRotation: 90,
                // minRotation: 90
              },
             grid: {
               display: true,
             }
            }
            
          }
        },
      }) 
  }

  reportTargetConnected() {
    if (this.chart) {

      this.chart.destroy()
      this.chart = new Chart(this.invchartTarget.getContext('2d'), {
        type: 'line',
        data: {
          labels: this.xdata,
          datasets: [{
            label: 'NSIL Token Price, USD',
            yAxisID: 'y',
            backgroundColor: 'rgba(161, 198, 247, 1)',
            borderColor: 'rgb(47, 128, 237)',
            data: this.ydata,
            order: 1,
            fill: true,
            tension: 0.4,
            pointRadius: 0
          },{
            label: 'Return Since Inception, %',
            yAxisID: 'TOGGLE_yaxis1',
            backgroundColor: 'white',
            borderColor: 'white',
            data: this.y1data,
            order: 2,
            hidden: true,
            // fill: true,
            tension: 0.4,
            pointRadius: 0
          }]
        },
        plugins: [InvChartDataLabels],
        options: {
          responsive: true,
          maintainAspectRatio: true,
          stacked: false,
          plugins: {
            title: {
              display: false,
              // text: 'Historical performance',
              color: 'white'
            },
            legend: {
              display: true,
              position: 'top',
              labels: {
                usePointStyle: true,
                boxWidth: 6
              },
              onClick: function(event, legendItem) {
                var y_axis_id = this.chart.data.datasets[legendItem.datasetIndex].yAxisID;
                // console.log('---------->'+ y_axis_id);
                // console.log('---------->'+ this.chart.options.scales.TOGGLE_yaxis1.ticks.display);
                // console.log('---------->'+ this.chart.data.datasets[legendItem.datasetIndex].hidden);
                // console.log('---------->'+ this.chart.data.datasets[0].fill);
               if(y_axis_id.startsWith('TOGGLE')){
                 //find by name yaxis                     
                   if(this.chart.options.scales.TOGGLE_yaxis1.ticks.display==true){

                      this.chart.options.scales.TOGGLE_yaxis1.ticks.display = false;
                      this.chart.data.datasets[0].fill = true;
                      this.chart.data.datasets[legendItem.datasetIndex].hidden = true;
                      this.chart.update();
                   }
                   else if(this.chart.options.scales.TOGGLE_yaxis1.ticks.display==false){

                    this.chart.options.scales.TOGGLE_yaxis1.ticks.display = true;
                    this.chart.data.datasets[0].fill = false;
                    this.chart.data.datasets[legendItem.datasetIndex].hidden = false;
                    this.chart.update();
                 }
             }
            }
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              intersect: false
            } 
          },
          scales: {
            y: {
              ticks: {
                color: 'white',
              },
              grid: {
                display: true,
              },
              position: 'left',
            },
            TOGGLE_yaxis1: {
              ticks: {
                color: 'white',
                display: false,
                callback: function(value, index, ticks) {
                  return value + '%';
              },
              },
              grid: {
                display: true,
              },
              position: 'right',
            },
            x: {
              ticks: {
                color: 'white',
                // autoSkip: false,
                // maxRotation: 90,
                // minRotation: 90
              },
             grid: {
               display: true,
             }
            }
            
          }
        },
      }) 

      this.chart.update()
    }
  }

  reportTargetDisconnected() {
    if (this.chart) {

      this.chart.destroy()
      this.chart = new Chart(this.invchartTarget.getContext('2d'), {
        type: 'line',
        data: {
          labels: this.xdata,
          datasets: [{
            label: 'NSIL Token Price, USD',
            yAxisID: 'y',
            backgroundColor: 'rgba(161, 198, 247, 1)',
            borderColor: 'rgb(47, 128, 237)',
            data: this.ydata,
            order: 1,
            fill: true,
            tension: 0.4,
            pointRadius: 0
          },{
            label: 'Return Since Inception, %',
            yAxisID: 'TOGGLE_yaxis1',
            backgroundColor: 'white',
            borderColor: 'white',
            data: this.y1data,
            order: 2,
            hidden: true,
            // fill: true,
            tension: 0.4,
            pointRadius: 0
          }]
        },
        plugins: [InvChartDataLabels],
        options: {
          responsive: true,
          maintainAspectRatio: true,
          stacked: false,
          plugins: {
            title: {
              display: false,
              // text: 'Historical performance',
              color: 'white'
            },
            legend: {
              display: true,
              position: 'top',
              labels: {
                usePointStyle: true,
                boxWidth: 6
              },
              onClick: function(event, legendItem) {
                var y_axis_id = this.chart.data.datasets[legendItem.datasetIndex].yAxisID;
                // console.log('---------->'+ y_axis_id);
                // console.log('---------->'+ this.chart.options.scales.TOGGLE_yaxis1.ticks.display);
                // console.log('---------->'+ this.chart.data.datasets[legendItem.datasetIndex].hidden);
                // console.log('---------->'+ this.chart.data.datasets[0].fill);
               if(y_axis_id.startsWith('TOGGLE')){
                 //find by name yaxis                     
                   if(this.chart.options.scales.TOGGLE_yaxis1.ticks.display==true){

                      this.chart.options.scales.TOGGLE_yaxis1.ticks.display = false;
                      this.chart.data.datasets[0].fill = true;
                      this.chart.data.datasets[legendItem.datasetIndex].hidden = true;
                      this.chart.update();
                   }
                   else if(this.chart.options.scales.TOGGLE_yaxis1.ticks.display==false){

                    this.chart.options.scales.TOGGLE_yaxis1.ticks.display = true;
                    this.chart.data.datasets[0].fill = false;
                    this.chart.data.datasets[legendItem.datasetIndex].hidden = false;
                    this.chart.update();
                 }
             }
            }
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              intersect: false
            } 
          },
          scales: {
            y: {
              ticks: {
                color: 'white',
              },
              grid: {
                display: true,
              },
              position: 'left',
            },
            TOGGLE_yaxis1: {
              ticks: {
                color: 'white',
                display: false,
                callback: function(value, index, ticks) {
                  return value + '%';
              },
              },
              grid: {
                display: true,
              },
              position: 'right',
            },
            x: {
              ticks: {
                color: 'white',
                // autoSkip: false,
                // maxRotation: 90,
                // minRotation: 90
              },
             grid: {
               display: true,
             }
            }
            
          }
        },
      }) 

      this.chart.update()
    }
  }

  get ydata() {
    var element = document.getElementById("y_metrics")
    var ydataAttribute = element.getAttribute('y-data-metrics');
    // console.log(JSON.parse(ydataAttribute))
    let ydata = JSON.parse(ydataAttribute)


    return ydata
  }

  get xdata() {
    var element = document.getElementById("x_metrics")
    var xdataAttribute = element.getAttribute('x-data-metrics');
    // console.log(JSON.parse(xdataAttribute))
    let xdata = JSON.parse(xdataAttribute)


    return xdata
  }

  get y1data() {
    var element = document.getElementById("y1_metrics")
    var y1dataAttribute = element.getAttribute('y1-data-metrics');
    // console.log(JSON.parse(y1dataAttribute))
    let y1data = JSON.parse(y1dataAttribute)


    return y1data
  }

  
}

