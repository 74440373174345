// src/controllers/hello_controller.js
import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'

export default class extends Controller {
  static targets = ["chart", "report"]

  connect() {
    
    Chartkick.options = {
      colors: ["red", "#666"],
      
    }
    

    // this.chart = new Chartkick.LineChart(this.chartTarget, this.data)   

    this.chart = new Chart(this.chartTarget.getContext('2d'), {
        type: 'line',
        data: {
          labels: ["sunday"],
          datasets: [{
            label: 'Last week',
            backgroundColor: 'rgba(161, 198, 247, 1)',
            borderColor: 'rgb(47, 128, 237)',
            data: this.data,
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
              }
            }]
          }
        },
      })

    
    
  }

  reportTargetConnected() {
    if (this.chart) {

      this.chart.destroy()
      this.chart = new Chart(this.chartTarget.getContext('2d'), {
        type: 'line',
        data: {
          labels: ["sunday"],
          datasets: [{
            label: 'Last week',
            backgroundColor: 'rgba(161, 198, 247, 1)',
            borderColor: 'rgb(47, 128, 237)',
            data: this.data,
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
              }
            }]
          }
        },
      })

      this.chart.update()
    }
  }

  reportTargetDisconnected() {
    if (this.chart) {

      this.chart.destroy()
      this.chart = new Chart(this.chartTarget.getContext('2d'), {
        type: 'line',
        data: {
          labels: ["sunday"],
          datasets: [{
            label: 'Last week',
            backgroundColor: 'rgba(161, 198, 247, 1)',
            borderColor: 'rgb(47, 128, 237)',
            data: this.data,
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
              }
            }]
          }
        },
      })

      this.chart.update()
    }
  }

  get data() {
    let data = {}

    this.reportTargets.forEach((report) => {
      data[report.dataset.date] = report.dataset.value
    })

    return data
  }
}

