import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["cookies"]
    
    connect() {
      


  }

  hideAccept() {
    console.log('Cookies Accepted')

  }
  
  hideReject() {
    console.log('Cookies Rejected')

  }

}